/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDr09W371azY7xW3q7c_JL014CAJTVRsoE",
  "appId": "1:884933814295:web:d8d349c1fdc5f82a475452",
  "authDomain": "schooldog-i-morgan-ga.firebaseapp.com",
  "measurementId": "G-N582T0EG11",
  "messagingSenderId": "884933814295",
  "project": "schooldog-i-morgan-ga",
  "projectId": "schooldog-i-morgan-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-morgan-ga.appspot.com"
}
